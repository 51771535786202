<template>
	<el-dialog title="购买套餐" class="pay-meal-dialog" :before-close="handleClose" :visible="visible" width="680px">
		<div style="padding: 10px">
			<el-row :gutter="20">
				<el-col :span="12" v-for="(item, idx) of items" :key="idx">
					<el-card class="box-card meal-card mt10" shadow="never">
						<div class="center" style="margin-bottom: 10px">
							<el-tag size="mini">
								<label> <i class="el-icon-goods"></i> 价格 ：{{ item.price }} 元</label>
							</el-tag>
							<!-- <el-tag size="mini ml10" v-if="isBigVip">
								<label> <i class="el-icon-goods"></i> 大会员价 ：{{ item.bigVipPrice }} 元</label>
							</el-tag> -->
						</div>
						<div class="center"><i class="el-icon-check mr4" /> 聊天点数：{{ item.chatCount }}</div>
						<div class="center"><i class="el-icon-check mr4" /> 有效天数 : <label>365 天</label></div>
						<div class="center mt16">
							<el-button type="primary" round size="mini" @click="pay(item.guid)">
								<i class="el-icon-bank-card"></i> 购买
							</el-button>
							<!-- <el-button type="primary" round size="mini" @click="bigVipPay(item.guid)" v-if="isBigVip">
								<i class="el-icon-bank-card"></i> 会员余额购买
							</el-button> -->
						</div>
						<el-tag v-if="item.isRecommend == '1'" class="remmd" effect="dark" type="success" size="mini">
							推荐
						</el-tag>
					</el-card>
				</el-col>

				<div v-html="payForm" ref="payForm"></div>
			</el-row>
		</div>
	</el-dialog>
</template>

<script>
import { buyChatGoods, getChatGoods, bigVipBuyChatGoods } from '@/apis/chat';
import { getUserBigVipInfoApi } from '@/apis/user';

export default {
	name: 'pay-meal-dialog',

	data() {
		return {
			visible: false,
			items: [],
			payForm: '',
			isBigVip: false,
		};
	},

	async mounted() {
		this.items = await getChatGoods();
		this.getUserBigVipInfo();
	},

	methods: {
		async pay(id) {
			let { payForm, orderNo } = await buyChatGoods(id);
			if (payForm) {
				this.payForm = window.atob(payForm);
				this.$nextTick(() => {
					const form = this.$refs.payForm.querySelector('form');
					if (form) {
						form.target = '_blank';
						form.submit();
					}
				});
			}
		},
		async bigVipPay(id) {
			let res = await bigVipBuyChatGoods({
				chatGoodsGuid: id,
				payEnv: 'big_vip_balance',
			});
			if (res.code === 0) {
				this.$message({
					message: '购买成功',
					type: 'success',
				});
				this.$emit('onBuyMeal');
				this.visible = false;
			} else {
				this.$message({
					message: res.msg,
					type: 'info',
				});
			}
		},
		async getUserBigVipInfo() {
			let res = await getUserBigVipInfoApi();
			this.isBigVip = res.data.isBigVip;
		},
		handleClose() {
			this.visible = false;
		},

		show() {
			this.visible = true;
		},

		close() {
			this.visible = false;
		},
	},
};
</script>

<style lang="scss">
.pay-meal-dialog {
	.el-dialog__body {
		padding: 10px;
	}

	.el-card__body {
		div {
			font-size: 12px;
			margin-bottom: 4px;
		}
	}

	.meal-card {
		position: relative;

		label {
			font-weight: 600;
		}
	}

	.remmd {
		position: absolute;
		top: 10px;
		right: 10px;
	}
}
</style>
