<template>
	<div class="login-register">
		<div class="logo-name flex mt30">
			<div class="">
				<img
					class="logo"
					src="https://xiaoyi-1317629730.cos.ap-guangzhou.myqcloud.com/pic1/ETMOhN65gLVQkzxXdcT2ZBat02WReWXy.jpg"
				/>
			</div>
			<div class="name">设宇宙-AI创作平台</div>
		</div>

		<div class="flex" style="justify-content: center; align-items: center">
			<div style="width: 60%" class="login-image-box">
				<carousel-3d :autoplay="true" :display="3" :autoplayHoverPause="true" :width="500" :height="337">
					<slide v-for="(slide, i) of slides" :index="i" :key="i">
						<img style="object-fit: cover" :src="lunboImg[i]" />
					</slide>
				</carousel-3d>
			</div>

			<div class="login-panel">
				<!-- v-show="qrImage" -->
				<el-tabs v-model="activeTab" @tab-click="tabClick(activeTab)">
					<el-tab-pane label="扫码登录" name="qrcode">
						<div class="qrcode-container">
							<el-image :src="qrImage">
								<div
									slot="placeholder"
									class="image-slot"
									style="
										width: 130px;
										height: 130px;
										background: #f1f5f9;
										font-size: 12px;
										color: var(--text-black-light);
									"
								>
									加载中<span class="dot">...</span>
								</div>
							</el-image>
							<div v-if="!scanSuccess" class="scan-tip" style="color: var(--text-black-light)">
								请使用微信扫码登录
							</div>
							<div v-else class="scan-tip">扫码成功，请在手机上确认登录</div>
						</div>
					</el-tab-pane>
					<el-tab-pane label="验证码登录" name="login">
						<el-form ref="loginForm" :model="loginForm" label-width="80px">
							<el-input
								prefix-icon="el-icon-phone-outline"
								placeholder="请输入手机号码"
								v-model="loginForm.phone"
							></el-input>
							<div class="flex mt20">
								<el-input
									placeholder="输入验证码【发送方：米蜗城市】"
									prefix-icon="el-icon-message"
									class="mr6"
									v-model="loginForm.code"
								></el-input>
								<a v-show="countdown < 1" class="send-code" @click="sendCode">发送验证码</a>
								<a v-show="countdown > 0" class="send-code">{{ countdown }}秒后重试</a>
							</div>
							<div class="mt20 flex">
								<el-button type="primary" class="login-btn" @click="login">登录</el-button>
							</div>
						</el-form>
					</el-tab-pane>
				</el-tabs>
			</div>
		</div>

		<div class="login-icon" v-html="svgIcons.wave"></div>
	</div>
</template>

<script>
import { getLogingQR, getUserInfo, loginSms, qrLoginCheck, sendSmsCode, aiMouseLoginApi } from '@/apis/user';
import { setToken } from '@/utils/auth';
import { Carousel3d, Slide } from 'vue-carousel-3d';
import { svgIcons } from '@/libs/svgicon';
import { merchantGuid } from '@/config';
export default {
	name: 'login',

	components: {
		Carousel3d,
		Slide,
	},

	mounted() {
		let params = new URLSearchParams(window.location.href.split('?')[1]);
		if (params.get('inviteCode')) {
			this.isInvite = true;
			this.inviteCode = params.get('inviteCode');
		}
		//ai鼠标登录
		if (params.get('h')) {
			let aiLoginCode = params.get('h');
			this.aiMouseLogin(aiLoginCode);
		}
		this.generateQrcode();
	},

	destroyed() {
		this.qrInterval && clearInterval(this.qrInterval);
	},

	data() {
		return {
			svgIcons,
			activeTab: 'qrcode',
			// activeTab: 'login',
			loginForm: {
				phone: '',
				code: '',
			},
			countdown: 0,
			scanSuccess: false,
			slides: 4,
			qrImage: '',
			qrCode: '',
			qrInterval: null,
			lunboImg: [
				'https://xiaoyi-1317629730.cos.ap-guangzhou.myqcloud.com/image/211d8c2aa0a14c7ea32e84cc5fbb7a7f.jpg',
				'https://xiaoyi-1317629730.cos.ap-guangzhou.myqcloud.com/image/37b9a19d0e0845acb55a8a9a0bc4b66e.jpg',
				'https://xiaoyi-1317629730.cos.ap-guangzhou.myqcloud.com/image/a18f966243274fb78dab116ee6f43ac3.jpg',
				'https://xiaoyi-1317629730.cos.ap-guangzhou.myqcloud.com/image/778994bd8d2141fcb2307149095e5193.jpg',
			],
			isInvite: false,
			inviteCode: '',
		};
	},

	methods: {
		async tabClick(tab) {
			if (tab == 'qrcode') {
				this.startScan();
			} else {
				clearInterval(this.qrInterval);
				this.qrInterval = null;
			}
		},
		async aiMouseLogin(code) {
			let res = await aiMouseLoginApi({ h: code });
			if (res.code === 0) {
				clearInterval(this.qrInterval);
				this.qrInterval = null;
				let token = res.data.token;
				console.log(token, 'tokentokentokentoken');
				this.$message({
					message: 'AI鼠标已识别正在登录....',
					type: 'success',
				});
				setToken(token);
				setTimeout(() => {
					this.$router.push('/app/chat');
				}, 1000);
			} else {
				this.$message({
					message: res.msg,
					type: 'error',
				});
			}
		},
		async getUserInfo() {
			let uInfo = await getUserInfo();
		},

		async login() {
			let { phone, code } = this.loginForm;
			let logReq = null;
			if (this.isInvite) {
				logReq = {
					phone: phone,
					smsCode: code,
					merchantGuid: merchantGuid,
					invitationText: this.inviteCode,
					invitationType: 'bigVip',
				};
			} else {
				logReq = {
					phone: phone,
					smsCode: code,
					merchantGuid: merchantGuid,
				};
			}
			let res = await loginSms(logReq);
			if (res.code === 0) {
				let token = res.data;
				setToken(token);
				clearInterval(this.qrInterval);
				setTimeout(() => {
					this.$router.push('/app/chat');
				}, 500);
			} else {
				this.$message({
					message: res.msg,
					type: 'error',
				});
			}
		},

		async generateQrcode() {
			let logReq = null;
			if (this.isInvite) {
				logReq = {
					invitationType: 'bigVip',
					invitationText: this.inviteCode,
					merchantGuid: merchantGuid,
				};
			} else {
				logReq = {
					merchantGuid: merchantGuid,
				};
			}
			let { codeImg, loginCode } = await getLogingQR(logReq);
			this.qrImage = codeImg;
			this.qrCode = loginCode;
			this.startScan();
		},

		startScan() {
			this.qrInterval = setInterval(async () => {
				let data = await qrLoginCheck(this.qrCode);
				if (data) {
					let { isLogin, token } = data;
					if (token) {
						// console.log(data)
						clearInterval(this.qrInterval);
						setToken(token);
						this.$router.push('/app/chat');
					}
				}
			}, 3000);
		},

		async sendCode() {
			let { phone } = this.loginForm;

			if (phone) {
				await sendSmsCode({
					imageCode: 'Jwjoz',
					phone: phone,
					from: 'xiaoyi',
				});

				this.countdown = 60;
				this.$message({ message: '验证码已发送', type: 'success' });

				let intervalId = setInterval(() => {
					this.countdown--;
					if (this.countdown <= 0) {
						clearInterval(intervalId);
					}
				}, 1000);
			}
		},
	},
};
</script>

<style lang="scss">
.login-register {
	width: 100%;
	height: 100%;
	background: #eaeff8;
	padding-top: 100px;
	box-sizing: border-box;
	overflow: hidden;
	.logo-name {
		margin: auto;
		margin-bottom: 100px;

		justify-content: center;
		align-items: center;

		.logo {
			width: 80px;
			height: 80px;
			border-radius: 50%;
			display: block;
			margin-right: 20px;
		}

		.name {
			font-size: 40px;
			background: linear-gradient(to right, red, blue);
			-webkit-background-clip: text;
			color: transparent;
			font-weight: 600;
		}
	}

	.login-panel {
		width: 24%;
		padding: 50px;
		background-color: rgba(255, 255, 255, 0.9);
		width: 400px;
		margin: auto auto auto 0;

		.el-tabs__header {
			margin-bottom: 30px;
		}

		.el-input__icon {
			line-height: 32px;
			font-size: 18px;
			margin-left: 4px;
		}

		.el-input {
			width: 100%;
			font-size: 14px;

			.el-input__inner {
				height: 50px !important;
				border-radius: 6px;
				padding-left: 40px;
			}
		}

		.el-tabs__nav > div {
			color: #300c8a;
			font-size: 20px;
			font-weight: 700;
			cursor: default;
		}

		.send-code {
			font-size: 14px;
			width: 90px;
			text-align: right;
			line-height: 50px;
			position: absolute;
			right: 20px;
			color: #300c8a;
		}
	}

	.login-btn {
		width: 100%;
		cursor: pointer;
		border-radius: 6px;
		height: 50px;
		line-height: 35px;
		background: linear-gradient(308.73deg, #2714f5 -11.66%, #a098ec 102.03%);
		font-weight: normal;
		font-size: 18px;
		color: #fff;
		letter-spacing: 3px;
		text-align: center;
	}
}

.qrcode-container {
	text-align: center;
	margin-top: 20px;

	img {
		width: 130px;
		height: 130px;
	}
}

.scan-tip {
	margin-top: 10px;
}
@media screen and (max-device-width: 1366px) and (-webkit-min-device-pixel-ratio: 2) {
	.login-register .logo-name .name {
		font-size: 24px;
	}
	.login-register {
		.login-image-box {
			display: none;
		}
		.login-panel {
			width: auto;
			margin: auto;
		}
		.login-icon {
			display: none;
		}
	}
}
</style>
