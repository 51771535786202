<template>
	<el-dialog :before-close="onCloseDialog" :visible="visible" width="812px">
		<div class="vip-dialog-box">
			<div class="vip-list-box">
				<div
					class="item"
					:class="{ active: activeItem?.sysId == item.sysId }"
					v-for="item in vipList"
					:key="item.guid"
					@click="onSelect(item)"
				>
					<div class="title">{{ item.cardName }}</div>
					<img class="icon" src="@/static/icon/vip-icon-year.png" />
					<div class="content" :style="{ backgroundImage: `url(${item.bgImg})` }">
						<div class="price-box">
							<div class="unit">¥</div>
							<div class="price">{{ item.cardPrice }}</div>
							<div class="label">(仅{{ item.averagePrice }}元/天)</div>
						</div>
						<div class="info-box">
							<!-- <div class="label">会员权限：</div> -->
							<div class="li" v-html="item.cardNotice"></div>
							<!-- <div class="li">1、无限次10大模型任意调用</div>
							<div class="li">2、解锁高级AI员工角色</div>
							<div class="li">3、专属客服</div>
							<div class="li">4、无限制的团队协助空间</div> -->
						</div>
						<div class="button">权益：{{ item.cardUseDays }}天</div>
					</div>
				</div>
				<!-- <div class="item">
					<div class="title">季卡会员</div>
					<img class="icon" src="@/static/icon/vip-icon-quater.png" />
					<div class="content">
						<div class="price-box">
							<div class="unit">¥</div>
							<div class="price">189</div>
							<div class="label">(仅0.51元/天)</div>
						</div>
						<div class="info-box">
							<div class="label">会员权限：</div>
							<div class="li">1、无限次10大模型任意调用</div>
							<div class="li">2、解锁高级AI员工角色</div>
							<div class="li">3、专属客服</div>
							<div class="li">4、无限制的团队协助空间</div>
						</div>
						<div class="button">权益：365天</div>
					</div>
				</div>
				<div class="item">
					<div class="title">月卡会员</div>
					<img class="icon" src="@/static/icon/vip-icon-month.png" />
					<div class="content">
						<div class="price-box">
							<div class="unit">¥</div>
							<div class="price">189</div>
							<div class="label">(仅0.51元/天)</div>
						</div>
						<div class="info-box">
							<div class="label">会员权限：</div>
							<div class="li">1、无限次10大模型任意调用</div>
							<div class="li">2、解锁高级AI员工角色</div>
							<div class="li">3、专属客服</div>
							<div class="li">4、无限制的团队协助空间</div>
						</div>
						<div class="button">权益：365天</div>
					</div>
				</div> -->
			</div>
			<div class="introduce-box">
				<div class="text-box">
					购买须知：
					<div v-html="activeItem.cardDesc"></div>
					<!-- <br />
					月卡会员权益仅限于购买后的一个月内有效。
					<br />
					请确保仅为个人使用，不得将会员权益分享或转让给他人。
					<br />
					请遵守服务使用规定，不得使用AI服务进行非法或违规活动。 -->
				</div>
				<div class="buy-box">
					<div class="price-box">
						<div class="unit">¥</div>
						<div class="price">{{ activeItem.cardPrice }}</div>
						<div class="label">(仅{{ activeItem.averagePrice }}元/天)</div>
					</div>
					<el-button class="button" :loading="buyStatus" @click="onBuyVip(activeItem.guid)"
						>开通权益</el-button
					>
				</div>
			</div>
		</div>
		<div v-html="payForm" ref="payForm"></div>
	</el-dialog>
</template>

<style lang="scss" scoped>
::v-deep .el-dialog {
	.el-dialog__body {
		padding: 0;
	}
	.el-dialog__header {
		padding: 0;
	}
}

.vip-dialog-box {
	padding: 40px 40px 25px 40px;
	background: #f4f8ff;
	box-sizing: border-box;

	.vip-list-box {
		display: flex;
		justify-content: space-between;
		padding-bottom: 20px;
		overflow-x: auto;
		.item {
			width: 230px;
			height: 317px;
			background: #fff;
			box-shadow: 0px 4px 16px 1px rgba(11, 18, 75, 0.08);
			border-radius: 20px 20px 20px 20px;
			margin-right: 20px;
			cursor: pointer;
			position: relative;
			&.active {
				background-color: #4c6afe;
				.title {
					color: #fff;
				}
			}
			.icon {
				width: 100px;
				height: 100px;
				display: block;
				position: absolute;
				right: 0;
				top: 0;
			}
			// &:first-child {
			// 	/* 样式设置 */
			// 	.content {
			// 		// background-image: url('../../../static/icon/vip-bg-year.png');
			// 	}
			// 	.button {
			// 		background: linear-gradient(268deg, #7d45f4 0%, #5c9bff 100%);
			// 	}
			// }
			// &:nth-child(2) {
			// 	/* 样式设置 */
			// 	.content {
			// 		// background-image: url('../../../static/icon/vip-bg-quarter.png');
			// 	}
			// 	.button {
			// 		background: linear-gradient(268deg, #747afc 0%, #9da5fc 100%);
			// 	}
			// }
			// &:nth-child(3) {
			// 	/* 样式设置 */
			// 	.content {
			// 		// background-image: url('../../../static/icon/vip-bg-month.png');
			// 	}
			// 	.button {
			// 		background: linear-gradient(268deg, #6fa1ff 0%, #7bc9fa 100%);
			// 	}
			// }
			.title {
				height: 55px;
				font-weight: 600;
				font-size: 24px;
				color: #282a36;
				display: flex;
				align-items: center;
				margin-left: 35px;
			}
			.content {
				width: 216px;
				height: 250px;

				background-size: 100% 100%;
				background-repeat: no-repeat;
				margin: 0 auto;
				padding-left: 26px;
				padding-top: 18px;
				box-sizing: border-box;
				.price-box {
					display: flex;
					line-height: 35px;
					// vertical-align: text-bottom;
					// margin-top: 1;
					align-items: baseline;

					.unit {
						color: #d61d25;
						font-size: 16px;
						font-weight: 600;
					}
					.price {
						color: #d61d25;
						font-size: 26px;
						font-weight: 600;
					}
					.label {
						font-size: 12px;
						margin-left: 5px;
					}
				}
			}
			.button {
				width: 100px;
				height: 30px;
				line-height: 30px;
				text-align: center;
				font-size: 13px;
				color: #fff;
				border-radius: 17px;
				margin-top: 19px;
				background: linear-gradient(268deg, #6fa1ff 0%, #7bc9fa 100%);
			}
			.info-box {
				.label {
					font-size: 14px;
					color: #473471;
					margin-bottom: 11px;
				}
				.li {
					font-size: 12px;
					line-height: 20px;
					color: #2e265d;
				}
			}
		}
	}
}
.introduce-box {
	margin-top: 42px;
	display: flex;
	justify-content: space-between;
	.text-box {
		font-size: 11px;
		color: #999999;
		line-height: 17px;
	}
	.buy-box {
		.price-box {
			display: flex;
			align-items: baseline;
			justify-content: center;
			.unit {
				font-size: 12px;
				color: #333333;
			}
			.price {
				font-size: 18px;
				font-weight: 600;
				color: #333333;
			}
			.label {
				font-size: 12px;
				margin-left: 5px;
				color: #999999;
			}
		}
		.button {
			width: 250px;
			height: 45px;
			display: flex;
			justify-content: center;
			align-items: center;
			font-size: 17px;
			color: #fff;
			background-color: #4c6afe;
			border-radius: 23px;
			margin-top: 10px;
			cursor: pointer;
		}
	}
}
</style>
<script>
import { getCardGoodsApi, buyCardApi, getPayVipResultApi, getPuserVipInfoApi } from '@/apis/user';
import { merchantGuid } from '@/config';
export default {
	name: 'pay-vip-dialog',

	data() {
		return {
			visible: false,
			vipList: [],
			activeItem: {
				guid: '',
				cardPrice: '',
				// cardDesc: '',
				averagePrice: 0,
				sysId: 0,
			},
			payForm: '',
			buyStatus: false,
		};
	},
	methods: {
		onCloseDialog() {
			this.visible = false;
		},
		show() {
			this.visible = true;
		},
		async getCardGoods() {
			let res = await getCardGoodsApi({ merchantGuid: merchantGuid });
			this.vipList = res.data;
			this.activeItem = res.data[0];
		},
		onSelect(item) {
			this.activeItem = item;
		},
		async onBuyVip(guid) {
			if (this.buyStatus) {
				return;
			}
			this.buyStatus = true;
			let res = await buyCardApi({
				merchantGuid: merchantGuid,
				cardGuid: guid,
				payType: 'alipay_pc',
			});
			if (res.code === 0) {
				this.interval(res.data.orderNo);
				if (res.data.payInfo.payForm) {
					this.payForm = window.atob(res.data.payInfo.payForm);
					this.$nextTick(() => {
						const form = this.$refs.payForm.querySelector('form');
						if (form) {
							form.target = '_blank';
							form.submit();
						}
					});
				}
			} else {
				this.$message({
					message: res.msg,
					type: 'info',
				});
			}
		},
		interval(orderNo) {
			let inter = 0;
			let count = 0;
			inter = setInterval(() => {
				if (count >= 12) {
					this.buyStatus = false;
					clearInterval(inter);
					return;
				}
				count++;
				this.getPayResult(orderNo, inter);
			}, 3000);
		},
		async getPayResult(orderNo, inter) {
			let res = await getPayVipResultApi({ orderNo });
			if (res.data.isPay) {
				clearInterval(inter);
				this.visible = false;
				this.buyStatus = false;
				this.$message({
					message: '购买成功,3秒后前往会员中心',
					type: 'success',
				});
				this.$emit('onBuyVip');
				setTimeout(() => {
					this.$router.push('/app/bigvip');
				}, 3000);
				// getPayResult(orderNo);
			}
		},
		// async getPayResult(orderNo) {
		// 	let res = await getPuserVipInfoApi({ orderNo });
		// 	console.log(res);
		// },
	},
	mounted() {
		this.getCardGoods();
	},
};
</script>
